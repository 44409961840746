export const data = [
    {
      lang: "ru",
      content: {
          h1: "До получения подарка остался всего один шаг!",
          p: "Заполни эту форму, укажи данные для связи, и мы свяжемся с вами, чтобы уточнит по какому адресу прислать подарок.",
          companyName: "Название компании",
          name: "Ваше имя",
          position: "Ваша должность",
          phoneNumber: "Номер телефона",
          email: "Ваша почта",
          button: "Отправить",
          phoneNumberError: "Пожалуйста, введите номер телефона или email.",
          emailError: "Пожалуйста, введите номер телефона или email.",
          phoneNumberInvalid: "Пожалуйста, введите корректный номер телефона (10 цифр).",
          submitSuccess: "Форма отправлена!<br><br> Наш менеджер свяжется с вами в ближайшее время. А пока можете ознакомиться с нашим сайтом.",
          site: "<a class='after-form-link' href='https://example.com'>Ознакомиться</a>",
      },
    },
    {
      lang: "en",
      content: {
          h1: "Form title",
          p: "A small descriptor in a couple of lines with instructions",
          companyName: "Company name",
          name: "Your name",
          position: "Job position",
          phoneNumber: "Phone number",
          email: "Email",
          button: "Submit",
          phoneNumberError: "Please enter a phone number or email.",
          emailError: "Please enter a phone number or email.",
          phoneNumberInvalid: "Please enter a valid phone number (10 digits).",
          submitSuccess: "Form submitted successfully!<br><br>  Our manager will contact you soon. In the meantime, you can explore our new website",
          site: "<a class='after-form-link' href='https://example.com'>Explore</a>",
      },
    },
    {
      lang: "kz",
      content: {
          h1: "kz Заголовок для формы kz",
          p: "kz Небольшой дескриптор в пару строк с инструкцией",
          companyName: "kz Название компании kz",
          name: "kz Ваше имя kz",
          position: "kz Ваша должность kz",
          phoneNumber: "kz Номер телефона kz",
          email: "kz Ваша почта kz",
          button: "kz Отправить kz",
          phoneNumberError: "kz Пожалуйста, введите номер телефона или email. kz",
          emailError: "kz Пожалуйста, введите номер телефона или email. kz",
          phoneNumberInvalid: "kz Пожалуйста, введите корректный номер телефона (10 цифр). kz",
          submitSuccess: "kz Форма отправлена!<br><br>  Наш менеджер свяжется с вами в ближайшее время. А пока можете ознакомиться с нашим сайтом",
          site: "<a class='after-form-link' href='https://example.com'>kz Ознакомиться</a>",
      },
    },
  ];
  