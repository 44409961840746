import MainPage from "./Components/MainPage"

function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
