import React, { useState } from "react";
import { data } from "../data/data";
import Form from "./Form";
import "./main-page.css";

function MainPage() {
  const [language, setLanguage] = useState("ru");

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const currentContent = data.find((item) => item.lang === language).content;

  return (
    <div className="app-body">
      <header className="header">
        <img className="header-logo" alt="logo" src="./images/logo-black.svg" />
        <div className="lang-toggle">
          <button
            className={`lang-button ${language === "ru" ? "active" : ""}`}
            onClick={() => handleLanguageChange("ru")}
          >
            RU
          </button>
          <span className="header-span"></span>
          <button
            className={`lang-button ${language === "en" ? "active" : ""}`}
            onClick={() => handleLanguageChange("en")}
          >
            EN
          </button>
          <span className="header-span"></span>
          <button
            className={`lang-button ${language === "kz" ? "active" : ""}`}
            onClick={() => handleLanguageChange("kz")}
          >
            KZ
          </button>
        </div>
      </header>
      <main>
        <div className="content">
          <img
            className="bubble-one"
            alt="bubble"
            src="./images/bubble-1.svg"
          />
          <h1 className="h1-text">{currentContent.h1}</h1>
          <img
            className="bubble-two"
            alt="bubble"
            src="./images/bubble-2.svg"
          />
          <p className="text">{currentContent.p}</p>
          <img className="cta-text" alt="" src="./images/text.svg" />
          <Form content={currentContent} />
        </div>
      </main>
      <footer className="footer">
        <img
          className="footer-logo"
          src="./images/logo-black-mini.svg"
          alt="logo"
        />
        <img
          className="footer-bg"
          src="./images/footer.svg"
          alt="shape"
        />
      </footer>
    </div>
  );
}

export default MainPage;
 