import React, { useState } from "react";
import "./main-page.css";

function Form({ content }) {
  const [formData, setFormData] = useState({
    company: "",
    name: "",
    position: "",
    phoneNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let newErrors = {};
    const phoneRegex = /^\d{10}$/;
    if (!formData.phoneNumber && !formData.email) {
      newErrors.phoneNumber = content.phoneNumberError;
      newErrors.email = content.emailError;
    } else if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = content.phoneNumberInvalid;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Отправка формы
      console.log("Form submitted:", formData);
      setIsSubmitted(true);
    }
  };

  if (isSubmitted) {
    return (
      <div className="after-form">
        <p className="after-form-text" dangerouslySetInnerHTML={{ __html: content.submitSuccess }}></p>
        <div className="button-div">
        <button className="submit-button" type="submit" dangerouslySetInnerHTML={{ __html: content.site }}> 
          
        </button>
      </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="kz-form"
      action="https://mytestsite.ru/script.php"
      method="post"
    >
      <label className="">
        <input
          name="company"
          type="text"
          className="form-input"
          placeholder={content.companyName}
          value={formData.company}
          onChange={handleChange}
        />
      </label>
      <label className="">
        <input
          name="name"
          type="text"
          className="form-input"
          placeholder={content.name}
          value={formData.name}
          onChange={handleChange}
        />
      </label>
      <label className="">
        <input
          name="position"
          type="text"
          className="form-input"
          placeholder={content.position}
          value={formData.position}
          onChange={handleChange}
        />
      </label>
      <label className="">
        <input
          name="phoneNumber"
          type="text"
          className="form-input"
          placeholder={content.phoneNumber}
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {errors.phoneNumber && (
          <span className="error">{errors.phoneNumber}</span>
        )}
      </label>
      <label className="">
        <input
          name="email"
          type="email"
          className="form-input"
          placeholder={content.email}
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <span className="error">{errors.email}</span>}
      </label>
      <div className="button-div">
        <button className="submit-button" type="submit">
          {content.button}
        </button>
      </div>
      <p className="underform-text">Нажимая кнопку отправить вы даете своё согласие на обработку, хранение и использование своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г.</p>
    </form>
  );
}

export default Form;
